import React from 'react'
import Top from '../components/home/Top';
import About from '../components/home/About';
import Team from '../components/home/Team';
import FAQ from '../components/home/FAQ';
import RoadMap from '../components/home/RoadMap';
import PreSaleMinting from '../components/mint/PreSaleMinting';

function MainPage() {
  return (
    <>
    <Top />
    <About />
    <PreSaleMinting />
    <RoadMap />
    <Team />
    <FAQ />
    </>
  )
}

export default MainPage