import React from 'react'
import { ConnectButton} from "web3uikit";

export default function Navigation(props) {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="container">
          <a className="navbar-brand logo-image" href="/">
            <img src="images/logo.svg" alt="FALCON EYE" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMenu"
            aria-controls="navbarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-awesome fa fa-bars"></span>
            <span className="navbar-toggler-awesome fa fa-times"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarMenu">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link page-scroll" href={props.page === 'home'?"#header":"/"}>
                  HOME <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href={props.page === 'home'?"#About":"/#About"} >
                  ABOUT US
                </a>
              </li>
              {props.page === 'home' && (
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#Pre-sale">
                  PRESALE
                </a>
              </li>
              )}
              <li className="nav-item">
                <a className="nav-link page-scroll" href={props.page === 'home'?"#roadmap":"/#roadmap"}>
                  ROADMAP
                </a>
              </li>              
              <li className="nav-item">
                <a className="nav-link page-scroll" href={props.page === 'home'?"#team":"/#team"}>
                  TEAM
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href={props.page === 'home'?"#faq":"/#faq"}>
                  FAQ
                </a>
              </li>
            </ul>

            <span className="nav-item wallet">
                <ConnectButton signingMessage="Welcome to Falcon Eye Club! This is a verification procedure. To continue please 'sign' this signature request for account ownership verification." />
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}
