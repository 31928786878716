import React from 'react'

function FAQ() {
    const css_padding ={paddingBottom: '20px'}
  return (
    <div id="faq" className="basic-1">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="blue text-center" style={css_padding}>Frequently Asked Questions</h1>
                    <ul id="accordion" className="col-sm-6 col-md-12">
                        <li>
                            <div id="choose" className="q" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                aria-controls="collapseOne">
                                What is Falcon Eye Club?
                                <span className="fa fa-chevron-up fa-1x text-info pull-right"></span>
                            </div>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                Falcon Eye Club is a community-driven collectibles <b>NFT project</b> on the Ethereum Blockchain.
                                <br /><br />
                                The main features of the project include a DAO-based decision-making process, that benefits all the
                                holders thanks to periodic <b>profits redistribution</b> and passive income generated via <b>in-house
                                    developed utilities and projects</b>, royalties from sales and collectively decided business ventures.
                                </div>
                            </div>
                        </li>

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                            aria-controls="collapseTwo">
                            Why should I own your NFT?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            Easy, just by holding you will become a member of the Club and will receive several <b>benefits and
                                perks</b>.
                            <br /><br />
                            Among the others:
                            <ul>
                                <li>Receive the profits that are <b>completely redistributed</b> among holders</li>
                                <li>Access to exclusive <b>offers</b> and <b>airdrops</b></li>
                                <li>Access to the project's development roadmap</li>
                                <li>Access to the FalconEye Discord and other community resources</li>
                            </ul>
                            <br />
                            In our <b>Falcon DAO</b>, all the members will have a right to vote – by the governance rule <b>1 NFT
                                = 1 Vote</b> – on the future developments of the project and how to invest its funds. The Club funds
                            will be held in <u>this transparently disclosed Community Wallet</u>.
                            </div>
                        </div>
                        </li>


                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                            aria-controls="collapseSix">
                            How can I become a member of the Falcon Eye Club?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseSix" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            To become a member of the Club, you need to <b>purchase NFT</b> which will grant you access to the
                            Club's <b>resources and benefits</b>, any other <b>offers</b> and <b>airdrops</b> that are available.
                            </div>
                        </div>
                        </li>                        

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                            aria-controls="collapseThree">
                            What is the launch date? and sale price?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                            <div className="card-body">
                            The launch date is <b>06 June at 01.00 UTC</b>, and the price is <b>0.05 ETH</b>, for the early falconers during the first Presale Day, capped at <b>20%</b> of the total supply.
                            and <b>0.07 ETH</b> during the Presale Day phase, while the price will be <b>0.1 ETH</b> in the public sale.
                            </div>
                        </div>
                        </li>

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                            aria-controls="collapseFour">
                            Will there be other Presales?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                            <div className="card-body">
                            The upcoming Presales will only be available for the <b>Falconers</b> who have already purchased the NFTs and 
                            they will also be able to mint the NFTs for a discounted price, please follow our <u><a href="https://twitter.com/FalconEyeClub">Twitter account</a></u> and <u><a href="https://www.instagram.com/falconeye.io/">Instagram account</a></u> for any updates.
                                 </div>
                        </div>
                        </li>

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false"
                            aria-controls="collapseFive">
                            What is the total supply? and how many NFTs will be issued?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                            <div className="card-body">
                            There will be a <b> total supply</b> of 10,000 NFTs, which will be available at discounted price during the Prsale phase.
                            <br /><br />
                            We will also be launching a <b>public sale</b> phase, where the NFTs will be available for sale. 
                            </div>
                        </div>
                        </li>

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false"
                            aria-controls="collapseSix">
                            Which wallet should I use?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseSix" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            You will need an <b>Ethereum</b> wallet for the minting process and the public sale.
                            </div>
                        </div>
                        </li>

                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false"
                            aria-controls="collapseSven">
                            Will the collection be listed on the secondary market?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                        <div id="collapseSeven" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            Yes! We are going to to be listed on <b>Opensea</b>.<br />
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className="q" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false"
                            aria-controls="collapseEight">
                            How much are the royalties from sales?
                            <span className="fa fa-chevron-down fa-1x text-info pull-right"></span>
                        </div>
                            <div id="collapseEight" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body">
                            The royalties from the NFT sales will be at <b>6%</b>, of which <b>2%</b> will be paid to the Club and <b>2%</b> will be paid to the
                            project's development. and the other <b>2%</b> will go to the team to fund back into the project through marketing, giveaways, and more. 
                            </div>
                        </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  </div>
  )
}

export default FAQ