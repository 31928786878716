import React from 'react'
export default function Copyright() {
  return (
    <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <p className="p-small">Copyright © {(new Date().getFullYear())} Falcon Eye Club<br />
          </p>
        </div>
      </div>
    </div>
  </div> 
  )
}
