import React from 'react'
import Timer from '../common/Timer'

function PreSaleMinting() {
    const stylePadding = {
        paddingTop: '16px'
    }
    return (
    <div id="Pre-sale" className="basic-2">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="image-container">
            <img className="img-fluid" src="images/whitelist.png" alt="whitelist" /><br /><br />
          </div>
        </div>
        <div className="col-lg-7">
          <div className="text-container">
            <h1 className="blue uppercase">Presale Minting</h1>
            <p>Presale mint is <b>0.07</b> ETH and with a very <b>low gas fee</b>. minting price during this presale is much cheaper than the public sale.<br /><br />
                There will be <b>10,000</b> NFTs in total available for minting.<br /><br />
                The opportunity is open to buying till <b>June 20, 2022</b>, do not miss the opportunity. Time is running out.
            </p>
              <Timer datetime="june 20, 2022 12:00:00" />
              <div className="text-center" style={stylePadding}><a href="/presale" className="king_wallet btn-airdrop">Mint Now</a></div>
          </div> 
        </div> 

      </div> 
    </div> 
  </div> 
  )
}

export default PreSaleMinting