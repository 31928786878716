import React from 'react'
import Navigation from '../components/common/Navigation';
import Wave from '../components/common/Wave';
import NewsLetter from '../components/common/NewsLetter';
import Social from '../components/common/Social';
import Copyright from '../components/common/Copyright';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import MainPage from './MainPage';

export default function Home() {
  return (
    <>
      <Navigation page="home"/>
      <MainPage />
      <Wave />
      <div className="footer">
        <GoogleReCaptchaProvider reCaptchaKey="6Le_1iYgAAAAAHadzO69_Dj6c0qLgTh5d-Y5_Kgz">
          <NewsLetter />
        </GoogleReCaptchaProvider>
        <Social />
        <Copyright />     
      </div>
    </>
  )
}
