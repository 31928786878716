import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PreSale from "./pages/PreSale";
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/presale" element={<PreSale />} />
    </Routes>
  );
}

export default App;
