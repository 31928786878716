import React from 'react'

function Team() {
    const css_padding ={paddingTop: '50px'}
  return (
    <div id="team" className="basic-2">
    <div className="container">
      <h1 className="blue text-center">Team</h1>
      <div className="row">
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-1.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Mr. Newtoni</h4>
              <span>Founder & AI/ML Developer</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-2.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Mr. Araeus</h4>
              <span>Founder & Lead Designer </span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-3.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Mr. Concolor</h4>
              <span>Web3 Developer & Blockchain Expert</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-4.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Ms. Amurensis</h4>
              <span>Social Media Manager & Artist </span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-5.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Ms. Femoralis </h4>
              <span>Marketing Lead & Coordinator</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 d-flex" style={css_padding}>
          <div className="team-member">
            <div className="member-img">
              <img src="images/team-6.jpg" className="img-fluid" alt="" />
            </div>
            <div className="member-info">
              <h4>Mr. Jugger</h4>
              <span>Art Director & Designer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Team