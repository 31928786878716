import React from 'react'
import MintForm from '../components/mint/MintForm';
import Orders from '../components/mint/Orders';
import MintMessage from '../components/mint/MintMessage';
import NFT from '../components/mint/NFT';
import { useMoralis} from "react-moralis";

function MintPage() {
  const {account } = useMoralis();
  return (
    <>
      <br />
      <br />
      <MintForm />
      <br />
      { account && (
        <>
          <MintMessage />
          <br />
          <br />
          <Orders />
          <br />
          <br />
          <NFT />
          <br />
        </>
      )
      }

    </>
  )
}

export default MintPage