import React from 'react'
import {useRef, useEffect} from 'react'

function Timer(props) {

    const day = useRef();
    const hour = useRef();
    const minute = useRef();
    const second = useRef();

    useEffect(() => {

        var deadline = new Date(props.datetime).getTime();             
        var x = setInterval(function() {
        var currentTime = new Date().getTime();                
        var t = deadline - currentTime; 
        var days = Math.floor(t / (1000 * 60 * 60 * 24)); 
        var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60)); 
        var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)); 
        var seconds = Math.floor((t % (1000 * 60)) / 1000); 
        day.current.innerHTML = days;
        hour.current.innerHTML = hours;
        minute.current.innerHTML = minutes;
        second.current.innerHTML = seconds;
        if (t < 0) {
            clearInterval(x);
            day.current.innerHTML = '0';
            hour.current.innerHTML = '0';
            minute.current.innerHTML = '0';
            second.current.innerHTML = '0';
        }
        }, 1000);

    }, [props.datetime]);
    
  return (
    <div className="timer d-flex justify-content-center">
        <div>
        <span className="days" id="day" ref={day}></span>
        <div className="smalltext">Days</div>
        </div>
        <div>
        <span className="hours" id="hour" ref={hour}></span>
        <div className="smalltext">Hours</div>
        </div>
        <div>
        <span className="minutes" id="minute" ref={minute}></span>
        <div className="smalltext">Minutes</div>
        </div>
        <div>
        <span className="seconds" id="second" ref={second}></span>
        <div className="smalltext">Seconds</div>
        </div>
        <p id="time-up"></p>
  </div>
  )
}

export default Timer